module.exports = {
  en: {
    translation: {
      home: {
        threekingdoms: "THREE KINGDOMS NFT",
        copyright: "Copyright 2022 three kingdoms club",
        comingsoon: "Coming Soon",
        bigGuysGather: "Meet the team",
        itsAllYou:
          "If you want to know more about the Three Kingdoms nft team members, please check out here. ",
        roadmap: "Roadmap",
        team: "Team",
        general: "General",
        battle: "Battle",
        home: "Home",
        preview: "Gallery",
      },
      roadmap: {
        p1_1: "Phase 1",
        p1_2: "4000 epic NFTs based on 80 classic Three Kingdoms characters, representative of the four forces of Wei, Shu, Wu, and Qun.",
        p1_3: "Release the combos of the Three Kingdoms' characters. The special combination holders can claim airdrop during phase 2.",
        p1_4: "Seed a community bank from phase 1 income.",
        p2_1: "Phase 2",
        p2_2: "The Three Kingdoms Land NFT will be minted based on the world map of the Three Kingdoms period.",
        p2_3: "Staking system and tokenomics.",
        p2_4: "Based on the Three Kingdoms NFT and the land NFT, different regional governers will be assigned. All heros will fight for their lands and communities during Phase 2.",
        p2_5: "Reward will be distributed from community bank to HEROS.",
        p3_1: "Phase 3",
        p3_2: "Mint the profile-pic interactive NFTs of the Three Kingdoms. Each work in this series is based on your activities in the Three Kingdoms world. eg. if you hold a Hero, you might get his/her weapons in your NFT. This is YOUR story in the community.",
        p3_3: "The Three Kingdoms Metaverse. Build games in Web3(Sandbox/Decentraland/NFT worlds). Host parties in Web3.",
        p3_4: "The Three Kingdoms DAO.",
      },
    },
  },
  zh: {
    translation: {
      home: {
        threekingdoms: "THREE KINGDOMS NFT",
        copyright: "Copyright 2022 three kingdoms club",
        comingsoon: "敬请期待",
        bigGuysGather: "团队介绍",
        itsAllYou: "英雄就是三国，三国就是英雄",
        roadmap: "路线图",
        team: "团队成员",
        general: "常规",
        battle: "掰头",
        home: "主页",
      },
      roadmap: {
        p1_1: "初出茅庐",
        p1_2: "围绕魏蜀吴群四个势力中具有代表性的80个三国经典人物发行4000个NFT。",
        p1_3: "公布三国人物组合关系，收集相应的英雄组合以获得第二阶段空投。",
        p1_4: "建立社区账户，转入第一阶段部分收益及版税收益用于项目后续发展。",
        p2_1: "群雄割据",
        p2_2: "根据三国时期的版图，发行三国土地NFT。",
        p2_3: "上线土地质押系统，囤地养$兵。",
        p2_4: "基于三国人物NFT和土地NFT建立魏蜀吴群四大势力，各个势力间出现主公、刺史、郡守等角色，带领号召所属势力逐鹿天下。",
        p2_5: "社区账户用来奖励天下英雄。",
        p3_1: "一齐天下",
        p3_2: "上线交互艺术头像类NFT。该作品会基于用户参与的活动进行快照，根据玩家在三国世界的轨迹生成属于玩家独一无二的NFT。例如，玩家持有关羽，有概率在获得青龙偃月刀。",
        p3_3: "完善三国NFT元宇宙的生态，例如，sandbox，webb等元宇宙概念生态。",
        p3_4: "三国社区自治系统。",
      },
    },
  },
};
